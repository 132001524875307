export enum DepFrequencyCode {
  Now = 1,
  OneTime = 2,
  Daily = 3,
  Monthly = 4,
  Quarterly = 5
}

export enum DepChannel {
  VizSFTP = 1,
  SDE = 2,
  MemberSFTP = 3,
  AzureBlobStorage = 4
}

export enum DepOutputFileType {
  CSV = 1,
  Pipe = 2,
  Parquet = 3,
  Hyper = 4
}

export enum DepOutputFileCompressionFormat {
  Zip = 1,
  SevenZ = 2,
  NoCompression = 3,
}

export enum ResponseStatus {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
}




