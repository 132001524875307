<viz-header title={{title}} [brandLink]="brandLink">
  
  <div *ngIf="(isAuthenticated$ | async) === false; else authenticated">
    <mat-tab-group (selectedTabChange)="redirect($event)">    
      <mat-tab label="Domains"></mat-tab>
      <mat-tab label="Login"> </mat-tab>
    </mat-tab-group>
  </div>
  <ng-template #authenticated>
    <nav mat-tab-nav-bar>
      <a
        (click)="openNotificationHistory()"
        mat-tab-link
        *ngIf="isAuthenticated$ | async"
      >
        Release Notes
      </a>  
    </nav>      
    <viz-nav-menu icon="help" id="iconHelp">
      <viz-nav-menu-item link="https://learning.vizientinc.com/Pages/default.aspx" displayText="Learning Center" id="learningCenter"></viz-nav-menu-item>
      <viz-nav-menu-item link="https://learning.vizientinc.com/Data_Delivery_Platform/Documents/DDP_User_Guide.pdf" displayText="User Guide" id="userGuide"></viz-nav-menu-item>
    </viz-nav-menu>    
    <viz-nav-menu icon="person" id="iconPerson">
      <viz-nav-menu-item routerLink="/" [userName]="(userName$ | async)" id="loggedIn"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/dashboard" displayText="Dashboard" id="dashboard"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/schedules" displayText="Schedules" id="schedules"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/my-notifications" displayText="Notifications" id="myNotifications"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/storage-accounts" displayText="Storage Accounts" id="storageAccounts"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="/" (click)="logout()" displayText="Log out" id="logout"></viz-nav-menu-item>
    </viz-nav-menu>
  </ng-template>
</viz-header>
