import { Domain } from 'src/app/models';
import { CardTitleComponent } from '../card-title/card-title.component';
import { DomainCollectionDescriptionComponent } from '../domain-collection-description/domain-collection-description.component';
import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';import { SharedModule } from 'src/app/shared';

@Component({
    selector: 'app-collection-card',
    standalone: true,
    template: `
    <mat-card *ngIf="domain">
      <app-card-title
        [domain]="domain"
        (click)="openSubDomainView.emit(domain)"
      ></app-card-title>

      <app-collection-description
        [lines]="4"
        [text]="domain.description"
        [matTooltip]="domain.description"
      ></app-collection-description>

      <ng-template *ngIf="domain.isComingSoon">
        <div class="access-denied-text">Coming Soon</div>
      </ng-template>

      <!-- adds a section to flexbox so more space in-between these two components -->
      <span *ngIf="!domain.isComingSoon" class="viz-body-1 learn-more" >
      </span>

      <a role="button" (click) = "openSubDomainView.emit(domain)" class="viz-body-1 learn-more" target="domain" > Learn more </a>
    </mat-card>
  `,
    styleUrls: ['./collection-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
      CardTitleComponent,
      DomainCollectionDescriptionComponent,
      CommonModule,
      SharedModule
    ]
})
export class CollectionCardComponent {
  @Input() domain!: Domain;
  @Output() openSubDomainView = new EventEmitter<any>();
}
