import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient } from '@angular/common/http';
import {
  Dataset,
  DatasetChannelDto,
  DatasetOutputFileCompressionFormatDto,
  DatasetOutputFileTypeDto,
  DatasetRollingDateDto,
  IDatasetFrequencyCode,
  SubDataset
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataSetService {
  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {}

  /**
   * Retrieve datasets by domain Id.
   *
   * @param domainId - The unique identifier of the dataset to fetch datasets.
   * @returns An Observable that emits a response containing the member details.
   */
  getDataSetsApi(domainId: number) {
    const url = `${this.envUrl.serverUrl}/v1/domains/${domainId}/datasets`;
    return this.http.get<Dataset[]>(url).pipe(retry(1));
  }

  /**
   * Retrieve datasets by domain Id.
   *
   * @param domainId - The unique identifier of the dataset to fetch datasets.
   * @returns An Observable that emits a response containing the member details.
   */
  getDataSetApi(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}`;
    return this.http.get<Dataset>(url).pipe(retry(1));
  }

   /**
    * Retrieve subdatasets by datasetId.
    *
    * @param datasetId - The unique identifier of the dataset to fetch subdatasets.
    * @returns An Observable that emits a response containing the member details.
    */
  getSubDataSetsApi(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/subdatasets`;
    return this.http.get<SubDataset[]>(url).pipe(retry(1));
  }

  /**
   * Retrieve channels types by datasetId.
   *
   * @param datasetId - The unique identifier of the dataset to fetch channels details.
   * @returns An Observable that emits a response containing the member details.
   */
  getDatasetChannels(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/channels`;
    return this.http.get<DatasetChannelDto[]>(url).pipe(retry(1));
  }

  /**
   * Retrieve file types by datasetId.
   *
   * @param datasetId - The unique identifier of the dataset to fetch file types.
   * @returns An Observable that emits a response containing the member details.
   */
  getDatasetOutputFileTypes(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/output-file-types`;
    return this.http.get<DatasetOutputFileTypeDto[]>(url).pipe(retry(1));
  }

  getDatasetOutputFileCompressionFormats(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/output-file-compression-formats`;
    return this.http.get<DatasetOutputFileCompressionFormatDto[]>(url).pipe(retry(1));
  }

  /**
   * Retrieve dataset frequencies by datasetId.
   *
   * @param datasetId - The unique identifier of the dataset to fetch frequencies.
   * @returns An Observable that emits a response containing the member details.
   */
  getDatasetFrequencies(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/frequencies`;
    return this.http.get<IDatasetFrequencyCode[]>(url).pipe(retry(1));
  }

  /**
   * Retrieve rolling dates settings by datasetId.
   *
   * @param datasetId - The unique identifier of the dataset to fetch rollingDates details.
   * @returns An Observable that emits a response containing the member details.
   */
  getDatasetRollingDates(datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/rollingDates`;
    return this.http.get<DatasetRollingDateDto[]>(url).pipe(retry(1));
  }
}
