import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, retry } from 'rxjs';

import { EnvironmentUrlService } from './environment-url.services';
import { StorageAccountCollection, StorageAccount, StorageAccountPassword } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StorageAccountService {
  v1 = 'v1';
  resourceName = 'storage-accounts';

  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {}

  create(storageAccount: StorageAccount): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}`;
    return this.http.post<StorageAccount>(url, storageAccount);
  }

  update(id: number, storageAccount: StorageAccount): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}`;
    return this.http.put<StorageAccount>(url, storageAccount);
  }

  updatePassword(storageAccountPassword: StorageAccountPassword , id: number): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}/update-password`;
    return this.http.patch<StorageAccount>(url, storageAccountPassword);
  }

  getById(id: number){
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}`;
    return this.http.get<StorageAccount>(url).pipe(retry(1));
  }

  getByStatusId(statusId: number) {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/statuses/${statusId}`;
    return this.http.get<StorageAccount[]>(url).pipe(retry(1));
  }

  getPaged(userId: number, pageNo: number, pageSize) {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/paged/?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<StorageAccountCollection>(url).pipe(retry(1));
  }
}
